//Imports
@import "./variables.scss";
@import "./header.scss";
@import "./main.scss";
@import "./footer.scss";
@import "./mediaQueries.scss";
@import "./map.scss";
@import "./calendar.scss";

* {
    box-sizing: border-box;
}

html {
    font-size: 16px !important;
}

body {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    background-color: $color-white;
    margin: 0;
    padding: 0;
}

//Fixes
.bg-light-blue {
    background-color: $color-lightBlue;
}

#cd-timeline .cd-timeline-img {
    z-index: 1 !important;
}

//Priblizenie sa k dizajnu homepage
#cd-timeline .cd-timeline-content h3 {
    color: $color-blue;
}

#cd-timeline .cd-timeline-content-number {
    color: rgba($color-blue, 0.2);
}

.result-container {
    .results {
        .result-item {
            &:hover {
                box-shadow: 0px 11px 41px -4px rgb(100 156 250 / 23%) !important;
            }
        }
    }
}

.overfooter {
    .subscribe-container {
        background: #f3f5f6 !important;

        .subscribe-info:first-child {
            color: $color-blue !important;
        }
    }
}

//

.main-wrapper {
    background: $color-white !important;
}

.itms-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 4rem;
}

a {
    text-decoration: none;
}

ul {
    margin: 0;
}

dd,
dl {
    margin: 0;
}

.active {
    color: $color-blue !important;

    span {
        background: $color-blue;
        width: 2.3125rem;
        height: 0.3rem;
        position: absolute;
        border-radius: 1rem;
        left: 50%;
        margin-left: -1.15625rem;
        bottom: 0;
    }
}

.link {
    color: $color-blue;
}

.sectionHeading {
    font-family: "Roboto slab", serif;
    font-size: 2.25rem;
    color: $color-blue;
}

.sectionText {
    line-height: 160%;
    margin-top: 0;
    margin-bottom: 1.5rem;
}

.hero {
    .hero__heading {
        color: $color-blue;
        font-family: "Roboto slab", serif;
        font-weight: bold;
        font-size: 3rem;
        margin-bottom: 1rem;
        margin-top: 0;
        max-width: 38.25rem;
    }

    .hero__text {
        font-weight: 400;
        line-height: 160%;
        max-width: 29.375rem;
        margin-top: 0;
        margin-bottom: 1.5rem;
    }

    .hero__button {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        background: $color-blue;
        border-radius: 12px;
        padding: 0.844rem 1.0625rem;
        width: max-content;
        color: $color-white;
        transition: background-color 0.3s ease;

        img {
            margin-right: 1rem;
        }

        &:hover {
            background: $color-blue-hover;
        }

        &:active {
            background: $color-blue-active;
        }
    }
}

.graph__descriptionContainer {
    display: flex;
}

.graph__description__text {
    position: relative;
    margin: 2.5rem 1rem 0.5rem 1rem;

    &::before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        left: -1rem;
        margin-top: -0.25rem;
        top: 50%;
        border-radius: 50%;
    }

    &.blue {
        &::before {
            background-color: $color-blue;
        }
    }

    &.yellow {
        &::before {
            background-color: $color-yellow;
        }
    }
}
