.main {
    .map {
        position: relative;
        margin-top: 3rem;

        .map__circle {
            fill: #ffefb7;
            stroke: #ffcc16;
            stroke-opacity: 1;
            stroke-width: 5;
            stroke-linecap: round;
            stroke-linejoin: round;
        }

        .map__circle-text {
            text-anchor: middle;
            stroke-width: 1px;
            font-size: 1.5rem;
        }

        .map__count-text {
            font-size: 1rem;
            color: $color-grey;
        }

        .map {
            filter: drop-shadow(0 0 40px #dde5f0);
            width: 100% !important;
            height: auto;
        }

        &__title {
            font-family: "Roboto slab", serif;
            line-height: 1.3;
            margin-bottom: 0;
            margin-top: 4rem;
        }
    }
}

@media screen and (max-width: 460px) {
    .main {
        .main__hero__grid {
            .main__hero__grid__leftSide {
                .main__map {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .main {
        .main__hero__grid {
            .main__hero__grid__leftSide {
                .main__map {
                    margin-bottom: 3rem;
                }
            }
        }
    }
}
