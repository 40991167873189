.main {
    .faqAndCalendar {
        .faqAndCalendar__grid {
            .calendar {
                background: $color-white;
                border-radius: 12px;
                padding: 2.6875rem;
                display: flex;
                flex-direction: column;
                text-align: center;

                .heading {
                    font-family: "Roboto slab", serif;
                    font-size: 2.25rem;
                    color: $color-blue;
                    margin: 0;
                    margin-bottom: 2.25rem;
                }

                .calendar__navigation {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 1.8125rem;

                    .calendar__navigation__arrowLeft {
                        transform: rotate(180deg);
                    }

                    .calendar__navigation__month {
                        margin: 0;
                        font-size: 1.5rem;
                        font-weight: 500;
                    }
                }

                .calendarContainer {
                    .calendar__table {
                        margin: 0 auto;
                    }

                    .table__body {
                        display: grid;
                        grid-template-rows: repeat(5, 1fr);
                        row-gap: 0.625rem;
                    }

                    .row {
                        display: grid;
                        grid-template-columns: repeat(7, 1fr);
                        grid-column-gap: 0.625rem;

                        .column {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .day {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 12px;
                                color: $color-black;
                                font-weight: 500;
                                width: 4rem;
                                height: 4rem;

                                &:hover {
                                    background: $color-lightBlue;
                                }
                            }
                        }

                        .column__selected {
                            background: $color-lightBlue;
                            border-radius: 12px;
                        }

                        .column__marked {
                            .day {
                                position: relative;

                                &::after {
                                    content: "";
                                    width: 0.75rem;
                                    height: 0.75rem;
                                    position: absolute;
                                    right: 0.3rem;
                                    bottom: 0.3rem;
                                    border-radius: 20%;
                                    border-left: 4px solid #ffc700;
                                    border-bottom: 4px solid #ffc700;
                                    transform: scaleX(-1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//CALENDAR SCROLL FIX AND STYLES
.fc-next-button,
.fc-prev-button {
    color: $color-blue !important;
    background: transparent !important;
    border: none !important;
}

.fc-today-button .fc-button .fc-button-primary {
    color: $color-blue !important;
    background: transparent !important;
    border-color: $color-blue !important;
}

.fc-toolbar-title {
    color: $color-black !important;
    font-weight: 500 !important;
}

.fc-daygrid .fc-dayGridMonth-view .fc-view,
.fc-scroller .fc-scroller-liquid-absolute {
    position: relative !important;
}

.fc-scroller {
    overflow: unset !important;
}

.fc-col-header,
.fc-scrollgrid-sync-table,
.fc-daygrid-body .fc-daygrid-body-unbalanced {
    width: 100% !important;
}

.fc-view-harness .fc-view-harness-active {
    height: 100% !important;
}

@media screen and (max-width: 1295px) {
    .main {
        .faqAndCalendar {
            .faqAndCalendar__grid {
                .calendar {
                    padding: 1.5625rem;

                    .calendarContainer {
                        .row {
                            .column {
                                .day {
                                    width: 3rem;
                                    height: 3rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .main {
        .faqAndCalendar {
            .faqAndCalendar__grid {
                .calendar {
                    padding: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .main {
        .faqAndCalendar {
            .faqAndCalendar__grid {
                .calendar {
                    padding: 1.625rem;
                    max-width: 34.375rem;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .main {
        .faqAndCalendar {
            .faqAndCalendar__grid {
                .calendar {
                    margin: 0;

                    .calendarContainer {
                        .row {
                            .column {
                                .day {
                                    width: 2.5rem;
                                    height: 2.5rem;
                                }
                            }

                            .column__marked {
                                .day {
                                    &::after {
                                        width: 0.35rem;
                                        height: 0.35rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .main {
        .faqAndCalendar {
            .faqAndCalendar__grid {
                .calendar {
                    .calendarContainer {
                        .calendar__table {
                            width: 100%;
                            margin: 0;
                        }

                        .row {
                            .column {
                                .day {
                                    width: 2rem;
                                    height: 2rem;
                                }
                            }

                            .column__selected {
                                border-radius: 0px;
                            }

                            .column__marked {
                                .day {
                                    &::after {
                                        width: 0.35rem;
                                        height: 0.35rem;
                                        right: 0rem;
                                        bottom: 0.1rem;
                                        border-left: 3px solid #ffc700;
                                        border-bottom: 3px solid #ffc700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .main {
        .faqAndCalendar {
            .faqAndCalendar__grid {
                .calendar {
                    padding: 1rem;

                    .calendarContainer {
                        .table__body {
                            row-gap: 1.2rem;
                        }

                        .row {
                            column-gap: 0.2rem;

                            .column__selected {
                                border-radius: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .main {
        .faqAndCalendar {
            .faqAndCalendar__grid {
                .calendar {
                    .calendarContainer {
                        .calendar__table {
                            margin: 0;
                            width: 100%;
                        }

                        .row {
                            .column {
                                .day {
                                    width: 1.6rem;
                                    height: 1.6rem;
                                }
                            }

                            .column__marked {
                                .day {
                                    &::after {
                                        width: 0.35rem;
                                        height: 0.35rem;
                                        right: -0.1rem;
                                        bottom: 0.1rem;
                                        border-left: 3px solid #ffc700;
                                        border-bottom: 3px solid #ffc700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
