$color-blue: #0073e6;
$color-grey: #666666;
$color-lightGrey: #e0e0e0;
$color-white: #fff;
$color-black: #000;
$color-lightBlue: #eff7fe;
$color-grey-blue: #4a5a73;
$color-yellow: #ffc700;

//HOVER COLORS
$color-blue-hover: #1981e8;
$color-lightBlue-hover: #f3f9fe;

//ACTIVE COLORS
$color-blue-active: #0068cf;
$color-lightBlue-active: #d8eafc;
