.main {
    .main__hero__grid {
        display: grid;
        margin-top: 2.5rem;
        grid-template-columns: repeat(2, auto);

        .main__hero__grid__rightSide {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            /*CARDS SECTION (RIGHT SIDE)*/
            .cardsContainer {
                .card {
                    background: $color-white;
                    padding: 1.5625rem;
                    width: 19rem;
                    border-radius: 12px;
                    margin-bottom: 2rem;
                    -webkit-box-shadow: 0px 11px 41px -4px rgba(100, 156, 250, 0.23);
                    box-shadow: 0px 11px 41px -4px rgba(100, 156, 250, 0.23);

                    .text-grey {
                        font-family: "Roboto", sans-serif;
                        font-size: 1.5rem;
                        color: $color-grey;
                        font-weight: 500;
                    }

                    .card__numberIconContainer {
                        display: flex;
                        align-items: baseline;

                        .number {
                            font-family: "Roboto slab", serif;
                            font-size: 3rem;
                            font-weight: bold;
                            margin: 0 0.875rem 0 0;
                        }
                    }

                    .card__description {
                        font-size: 1rem;
                        color: $color-grey;
                        margin: 0;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .grid__eurofondy {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 2rem;
        margin-top: 2.5rem;

        .grid__eurofondy__rightSide {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .eurofondy__hero-image {
                width: 100%;
                max-width: 30rem;
                height: auto;
                margin-top: -3.45rem;
            }
        }
    }

    /*ITEMS SECTION (SECTION WITH ICONS IN SQUARES)*/
    .items {
        display: flex;
        justify-content: space-between;
        text-align: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 3rem;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.25rem;
            color: $color-black;
            font-weight: 500;
            transition: color 0.3s ease;
            width: 14.8125rem;
            margin-top: 1rem;
            text-align: center;

            .item__description {
                font-size: 1.5rem;
                line-height: 140%;
            }

            &:hover {
                color: $color-blue;

                .item__imageContainer {
                    background: $color-lightBlue-hover;
                }
            }

            &:active {
                color: $color-blue;

                .item__imageContainer {
                    background: $color-lightBlue-active;
                }
            }

            .item__imageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $color-white;
                width: 5.53125rem;
                height: 5.53125rem;
                border-radius: 12px;
                transition: background-color 0.3s ease;
                -webkit-box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
                box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
            }
        }
    }

    /*OPERATION PROGRAMS SECTION*/
    .operationPrograms {
        margin-top: 3.75rem;

        .graphContainer {
            background-color: $color-white;
            border-radius: 12px;
            width: 100%;
            margin-bottom: 3rem;
            transition: box-shadow 0.3s ease;
            padding: 1.5rem;

            .graph {
                width: 100%;
                height: auto;
            }

            &:hover {
                -webkit-box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
                box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
                cursor: pointer;
            }
        }

        .cardsContainer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 2rem;
            grid-row-gap: 2rem;

            .card {
                border-radius: 12px;
                background: $color-white;
                position: relative;
                color: $color-black;
                transition: box-shadow 0.3s ease;
                display: flex;
                flex-direction: column;

                &:hover {
                    -webkit-box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
                    box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
                }

                &:active {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }

                .card__content {
                    display: grid;
                    grid-template-columns: min-content auto;
                    padding: 1.5rem 1.5rem 0 1.5rem;

                    .card__heading {
                        font-family: "Roboto slab", serif;
                        font-size: 1.5rem;
                        margin-top: 0;

                        .card__link {
                            color: $color-black;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                            }
                        }
                    }

                    .card__graph {
                        width: 7.25rem;
                        margin-right: 1.5rem;
                    }

                    .columnContainer {
                        .column {
                            display: grid;
                            grid-template-columns: 11.4rem 6.8125rem 3.4375rem;
                            justify-content: space-between;
                            position: relative;
                            max-width: 24rem;

                            .column__description {
                                margin-top: 0;
                                margin-bottom: 0.75rem;

                                &:nth-child(2),
                                &:nth-child(3) {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }

                .arrowContainer {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: auto;

                    .arrow__wrapper {
                        width: 4.4375rem;
                        height: 4.4375rem;
                        background: $color-lightBlue;
                        display: flex;
                        align-items: center;
                        border-radius: 12px 0 12px 0;

                        .arrow__icon {
                            display: block;
                            margin: 0 auto;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .moreInformationLinkContainer {
            display: flex;
            justify-content: center;
            margin-top: 2.3125rem;

            .link {
                color: $color-blue;
                font-size: 1rem;
                margin-top: 0;
                margin-bottom: 0;
                display: flex;
                align-items: center;

                .icon {
                    margin-left: 1rem;
                    transition: 0.3s ease;
                }

                &:hover {
                    .icon {
                        margin-left: 1.5rem;
                        transition: 0.3s ease;
                    }
                }
            }
        }
    }

    /*FAQ AND CALENDAR SECTION*/
    .faqAndCalendar {
        margin-top: 4.375rem;
        margin-bottom: 2.5rem;

        .faqAndCalendar__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 2rem;

            .faq {
                background: $color-white;
                border-radius: 12px;
                padding: 2.6875rem;

                .sectionHeading {
                    margin-top: 0;
                }

                .accordion {
                    border-bottom: 1px solid $color-lightGrey;

                    .accordion__heading {
                        cursor: pointer;
                        width: 100%;
                        font-weight: 100;
                        font-size: 1.5rem;
                        margin: 0;

                        [aria-expanded="true"] .vert {
                            display: none;
                        }

                        [aria-expanded] rect {
                            fill: $color-blue;
                        }

                        &:hover {
                            background: $color-lightBlue-hover;
                        }

                        &:active {
                            background: $color-lightBlue-active;
                        }
                    }

                    .accordion__title {
                        all: inherit;
                        border: 0;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        padding: 2rem 0;

                        .accordion__indicator {
                            height: 1.5rem;
                            margin-left: 0.49rem;
                        }
                    }

                    .accordion__description {
                        line-height: 160%;
                        margin: 0;
                        padding: 0 1.5rem 2rem 0;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    /*ELECTRONIC TIMESHEET SECTION*/
    .electronicTimesheet {
        margin-top: 5rem;

        .electronicTimesheet__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 2rem;

            .textContainer {
                display: flex;
                align-items: center;

                .buttonContainer {
                    display: flex;

                    .button__login {
                        width: max-content;
                        background: $color-blue;
                        border-radius: 12px;
                        padding: 0.875rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $color-white;
                        margin-right: 1.5rem;
                        transition: background-color 0.3s ease;

                        .icon {
                            margin-right: 0.8125rem;
                        }

                        &:hover {
                            background: $color-blue-hover;
                        }

                        &:active {
                            background: $color-blue-active;
                        }
                    }

                    .moreInformationLinkContainer {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .link {
                            color: $color-blue;
                            font-size: 1rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .icon {
                                margin-left: 1rem;
                                transition: 0.3s ease;
                            }

                            &:hover {
                                .icon {
                                    margin-left: 1.5rem;
                                    transition: 0.3s ease;
                                }
                            }
                        }
                    }
                }
            }

            .imageContainer {
                display: flex;
                justify-content: center;
            }
        }
    }

    /*PRE-FOOTER SECTION*/
    .preFooter {
        background: $color-white;
        padding-top: 2rem;
        padding-bottom: 4.375rem;

        .heading {
            margin-top: 0;
            font-family: "Roboto slab", serif;
            font-size: 1.5rem;
            color: $color-blue;
            display: flex;
            align-items: center;
            margin-bottom: 1.25rem;

            .icon {
                margin-right: 1rem;
                width: 2rem;
            }
        }

        .columnContainer {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 3.75rem;
            grid-row-gap: 0px;

            .column {
                .column__heading {
                    margin-top: 0;
                    margin-bottom: 1rem;
                }

                .column__text {
                    color: $color-grey-blue;
                    font-weight: 500;
                    margin-bottom: 1rem;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                .column__link {
                    color: $color-blue;
                    display: inline-block;
                    font-weight: 500;

                    &:first-of-type {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

/*FAQ AND HOW TO START SECTION*/
.faqAndHowToStart {
    margin-top: 4rem;
    background-color: $color-white;
    padding: 2rem 0;

    .faqAndHowToStart__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2rem;

        .howToStart {
            .howToStart__column {
                margin-bottom: 4.375rem;

                .column__heading {
                    font-family: "Roboto slab", serif;
                    font-size: 2.25rem;
                    color: $color-blue;
                    margin: 0;
                    margin-bottom: 1rem;
                }

                .column__text {
                    line-height: 160%;
                }
            }

            .buttonContainer__moreInformation {
                display: flex;
                align-items: center;

                .link {
                    color: $color-blue;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        margin-left: 1rem;
                        transition: 0.3s ease;
                    }

                    &:hover {
                        .icon {
                            margin-left: 1.5rem;
                            transition: 0.3s ease;
                        }
                    }
                }
            }
        }

        .faq {
            .sectionHeading {
                margin: 0;
            }

            .accordion {
                border-bottom: 1px solid $color-lightGrey;

                .accordion__heading {
                    cursor: pointer;
                    width: 100%;
                    font-weight: 100;
                    font-size: 1.5rem;
                    margin: 0;

                    [aria-expanded="true"] .vert {
                        display: none;
                    }

                    [aria-expanded] rect {
                        fill: $color-blue;
                    }

                    &:hover {
                        background: $color-lightBlue-hover;
                    }

                    &:active {
                        background: $color-lightBlue-active;
                    }
                }

                .accordion__title {
                    all: inherit;
                    border: 0;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 2rem 0;

                    .accordion__indicator {
                        height: 1.5rem;
                        margin-left: 0.49rem;
                    }
                }

                .accordion__description {
                    line-height: 160%;
                    margin: 0;
                    padding: 0 1.5rem 2rem 0;
                    font-size: 1rem;
                }
            }
        }
    }
}

/*OTHER DATA ABOUT OPERATION PROGRAMS SECTION*/
.otherDataAboutOperationPrograms {
    margin-top: 3.4rem;
    margin-bottom: 2.5rem;

    .otherDataAboutOperationPrograms__accordion {
        border-bottom: 1px solid $color-lightGrey;

        .accordion__heading {
            cursor: pointer;
            width: 100%;
            font-weight: 100;
            font-size: 1.5rem;
            margin: 0;

            [aria-expanded="true"] .vert {
                display: none;
            }

            [aria-expanded] rect {
                fill: $color-blue;
            }

            &:hover {
                background: $color-lightBlue-hover;
            }

            &:active {
                background: $color-lightBlue-active;
            }
        }

        .accordion__title {
            all: inherit;
            border: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 2rem 0;

            .accordion__indicator {
                height: 1.5rem;
                margin-left: 0.49rem;
            }
        }

        .accordion__description-container {
            padding-bottom: 2rem;

            .accordion__descriptionContainer {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 2rem;
                grid-row-gap: 2rem;

                .accordion__description {
                    background-color: $color-white;
                    width: 100%;
                    border-radius: 12px;
                    transition: box-shadow 0.3s ease;
                    padding: 1.5rem;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &:hover {
                        -webkit-box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
                        box-shadow: 2px 17px 42px 5px rgba(167, 204, 255, 0.26);
                        cursor: pointer;
                    }

                    .accordion__description__text {
                        position: relative;
                        margin-left: 1rem;
                        margin-top: 2.5rem;
                        margin-bottom: 0.5rem;

                        &::before {
                            content: "";
                            width: 0.5rem;
                            height: 0.5rem;
                            background-color: $color-blue;
                            position: absolute;
                            left: -1rem;
                            margin-top: -0.25rem;
                            top: 50%;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

/*DETAIL OPERACNEHO PROGRAMU INTRO SECTION*/
.operationProgramDetail {
    background-color: $color-white;
    padding: 2rem 0;

    .iconContainer {
        .link {
            color: $color-blue;
            font-size: 1rem;
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-bottom: 2.5rem;

            .icon {
                margin-right: 1rem;
            }
        }
    }

    .heading {
        color: $color-blue;
        font-family: "Roboto slab", serif;
        font-weight: bold;
        font-size: 3rem;
        margin-bottom: 3.125rem;
        margin-top: 0;
    }

    .operationProgram__code {
        margin-top: 0;
        margin-bottom: 2.5rem;
    }

    .operationProgramDetail__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2rem;

        .operationProgramDetail__grid__leftSide {
            .text {
                line-height: 160%;
                margin-bottom: 2.5rem;
            }
        }

        .operationProgramDetail__grid__rightSide {
            .graphContainer {
                padding: 1.5rem;
                background-color: $color-lightBlue;
                border-radius: 12px;

                &:first-of-type {
                    margin-bottom: 4rem;
                    padding: 0;
                    background: none;
                }

                .graph {
                    width: 100%;
                    height: auto;
                }

                .card {
                    border-radius: 12px;
                    background: $color-white;
                    position: relative;
                    color: $color-black;
                    transition: box-shadow 0.3s ease;
                    display: flex;
                    flex-direction: column;

                    .card__content {
                        display: block;
                        grid-template-columns: min-content auto;

                        .card__heading {
                            font-family: "Roboto slab", serif;
                            font-size: 1.5rem;
                            margin-top: 0;

                            .card__link {
                                color: $color-black;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                }
                            }
                        }

                        .card__graph {
                            width: 4.5rem;
                            float: left;
                            margin-right: 1.5rem;

                            &::after {
                                content: "";
                                clear: both;
                                display: table;
                            }
                        }

                        .columnContainer {
                            .column {
                                display: grid;
                                grid-template-columns: 11.4rem 6.8125rem 4.4375rem;
                                justify-content: space-between;
                                position: relative;
                                max-width: 24rem;

                                .column__description {
                                    margin-top: 0;
                                    margin-bottom: 0.75rem;

                                    &:nth-child(2),
                                    &:nth-child(3) {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*OPERATION PROGRAM DETAIL CARDS SECTION*/
.operationProgramData {
    .cardsContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;
        padding-top: 2rem;
        margin-bottom: 2.5rem;

        .card {
            display: flex;
            justify-content: center;
            background: $color-white;
            padding: 1.5625rem;
            border-radius: 12px;
            -webkit-box-shadow: 0px 11px 41px -4px rgba(100, 156, 250, 0.23);
            box-shadow: 0px 11px 41px -4px rgba(100, 156, 250, 0.23);

            .main__operationProgramDetail__cards__card-greyText {
                font-family: "Roboto", sans-serif;
                font-size: 1.5rem;
                color: $color-grey;
                font-weight: 500;
            }

            .numberIconContainer {
                display: flex;
                align-items: baseline;

                .number {
                    font-family: "Roboto slab", serif;
                    font-size: 3rem;
                    font-weight: bold;
                    margin: 0 0.875rem 0 0;
                }
            }

            .card__description {
                font-size: 1rem;
                color: $color-grey;
                margin: 0;
                font-weight: 500;
            }

            .card__link {
                color: $color-blue;
                text-decoration: underline;
            }
        }
    }
}

/*MOBILE APPLICATION SECTION*/
.mobileApplication {
    background-color: $color-white;
    padding: 2rem 0;

    .iconContainer {
        .link {
            color: $color-blue;
            font-size: 1rem;
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-bottom: 2.5rem;

            .icon {
                margin-right: 1rem;
            }
        }
    }

    .heading {
        color: $color-blue;
        font-family: "Roboto slab", serif;
        font-weight: bold;
        font-size: 3rem;
        margin-bottom: 3.125rem;
        margin-top: 0;
    }

    .mobileApplication__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2rem;

        .mobileApplication__grid__leftSide {
            .text {
                line-height: 160%;
                margin-bottom: 2.5rem;
            }

            .listContainer {
                margin-bottom: 2.5rem;

                .text {
                    margin-bottom: 0rem;
                }

                .list {
                    list-style-type: initial;
                    padding-left: 1.5rem;

                    .list__item {
                        line-height: 160%;
                    }
                }
            }
        }

        .mobileApplication__grid__rightSide {
            .imageContainer {
                display: flex;
                justify-content: center;
                margin-bottom: 2.125rem;

                .image {
                    width: 100%;
                    height: auto;
                }
            }

            .storesContainer {
                .image {
                    max-height: 3.75rem;
                    margin-right: 1rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

/*OPEN DATA / OPEN API SECTION*/
.openData {
    background-color: $color-white;
    padding: 2rem 0;

    .sectionHeading {
        margin-top: 0;
    }

    .iconContainer {
        .link {
            color: $color-blue;
            font-size: 1rem;
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-bottom: 2.5rem;

            .icon {
                margin-right: 1rem;
            }
        }
    }

    .heading {
        color: $color-blue;
        font-family: "Roboto slab", serif;
        font-weight: bold;
        font-size: 3rem;
        margin-bottom: 3.125rem;
        margin-top: 0;
        max-width: 43rem;
    }

    .text {
        line-height: 160%;
        margin-bottom: 2.5rem;

        .link {
            color: $color-blue;
            font-weight: 500;
        }
    }

    .listContainer {
        margin-bottom: 2.5rem;

        .text {
            margin-bottom: 0rem;
        }

        .list {
            list-style-type: initial;
            padding-left: 1.5rem;

            .list__item {
                line-height: 160%;
            }
        }
    }

    .openApi__section {
        .text:last-of-type {
            margin-bottom: 0;
        }
    }

    .openApi__section,
    .openData__section {
        max-width: 43rem;
    }
}

/*EPV SECTION*/
.elektronicky-pracovny-vykaz {
    background-color: $color-white;
    padding: 2rem 0;

    .iconContainer {
        .link {
            color: $color-blue;
            font-size: 1rem;
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-bottom: 2.5rem;

            .icon {
                margin-right: 1rem;
            }
        }
    }

    .heading {
        color: $color-blue;
        font-family: "Roboto slab", serif;
        font-weight: bold;
        font-size: 3rem;
        margin-bottom: 3.125rem;
        margin-top: 0;
    }

    .elektronicky-pracovny-vykaz__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2rem;

        .elektronicky-pracovny-vykaz__grid__leftSide {
            .text {
                line-height: 160%;
                margin-bottom: 2.5rem;
            }

            .listContainer {
                margin-bottom: 2.5rem;

                .text {
                    margin-bottom: 0rem;
                }

                .list {
                    list-style-type: initial;
                    padding-left: 1.5rem;

                    .list__item {
                        line-height: 160%;
                    }
                }
            }
        }

        .elektronicky-pracovny-vykaz__grid__rightSide {
            .imageContainer {
                display: flex;
                justify-content: center;
                margin-bottom: 2.125rem;

                .image {
                    width: 100%;
                    max-width: 550px;
                    height: auto;
                }
            }
        }
    }
}
