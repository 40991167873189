.header {
    background-color: $color-white;
    font-size: 0.875rem;
    font-weight: 500;

    .header__grid {
        display: grid;
        grid-template-columns: min-content auto max-content;
        align-items: center;

        .logoContainer {
            margin-right: 1.5rem;

            .logo {
                width: 5rem;
            }
        }

        .mobileNavigation__iconContainer {
            display: none;

            .hamburgerIcon__button,
            .closeIcon__button {
                background: none;
                border: none;
                padding: 0;
            }

            .hamburgerIcon__icon,
            .closeIcon__icon {
                width: 2rem;
                height: auto;
            }

            .closeIcon__button {
                display: none;
            }
        }

        .desktopNavigation {
            list-style-type: none;
            padding-left: 0;
            display: flex;
            flex-direction: row;
            margin: 0;

            .desktopNavigation__item {
                line-height: 5;
            }

            .desktopNavigation__link {
                color: $color-black;
                display: block;
                height: 100%;
                position: relative;
                font-size: 1rem;
                padding: 0 0.75rem;
                transition: background-color 0.3s ease;

                &:hover {
                    background: $color-lightBlue-hover;
                }
            }
        }

        .desktopNavigation__buttonContainer {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .desktopNavigation__button__register {
                color: $color-blue;
                background: $color-lightBlue;
                border-radius: 12px;
                width: 7.0625rem;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1.5rem;
                font-size: 1rem;
                transition: background-color 0.3s ease;

                &:hover {
                    background: $color-lightBlue-hover;
                }

                &:active {
                    background: $color-lightBlue-active;
                }
            }

            .desktopNavigation__button__login {
                width: 7.1875rem;
                background: $color-blue;
                border-radius: 12px;
                width: 9.1875rem;
                height: 3rem;
                display: flex;
                font-size: 1rem;
                justify-content: space-evenly;
                align-items: center;
                margin-right: 1.5rem;
                color: $color-white;
                transition: background-color 0.3s ease;

                &:hover {
                    background: $color-blue-hover;
                }

                &:active {
                    background: $color-blue-active;
                }
            }

            .desktopNavigation__button__languageSelector {
                a {
                    color: $color-black;
                    display: block;
                    height: 100%;
                    font-size: 1rem;
                    padding: 0 0.75rem;
                    transition: background-color 0.3s ease;
                    line-height: 5rem;

                    &:hover {
                        background: $color-lightBlue-hover;
                    }
                }
            }
        }

        .mobileNavigation {
            padding: 0;
            position: fixed;
            display: none;
            top: 4.25rem;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 4;
            background: $color-white;
            overflow: scroll;
            text-align: center;
            transform: translateY(-0.3125rem);

            .mobileNavigation__content {
                padding: 2rem 0;
                border-bottom: 1px solid #e0e0e0;

                .mobileNavigation__itemsContainer {
                    list-style: none;
                    padding-left: 0;
                    margin: 0;
                    display: flex;
                    padding: 0;
                    flex-flow: column;

                    .mobileNavigation__item {
                        margin-bottom: 2rem;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        .mobileNavigation__link {
                            font-size: 1rem;
                            padding: 0.5rem 0;
                            display: inline-block;
                            color: $color-black;
                        }
                    }
                }
            }

            .mobileNavigation__buttonContainer {
                display: block;
                padding: 2rem 0;

                .mobileNavigation__button__login {
                    background: $color-blue;
                    border-radius: 12px;
                    width: 100%;
                    height: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $color-white;
                    transition: background-color 0.3s ease;
                    font-size: 1rem;
                    margin-bottom: 4rem;

                    &:hover {
                        background: $color-blue-hover;
                    }

                    &:active {
                        background: $color-blue-active;
                    }

                    img {
                        margin-right: 1rem;
                    }
                }

                .mobileNavigation__buttonWrapper {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    .mobileNavigation__button__languageSelector {
                        font-size: 1rem;
                        font-family: "Roboto", serif;
                        font-weight: 500;
                        height: 3rem;
                        background: $color-lightBlue;
                        padding: 1rem;
                        border-radius: 12px;
                        width: 5rem;
                        color: $color-black;
                    }

                    .mobileNavigation__button__registrate {
                        color: $color-blue;
                        background: $color-lightBlue;
                        border-radius: 12px;
                        width: 100%;
                        height: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 1.5rem;
                        font-size: 1rem;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background: $color-lightBlue-hover;
                        }

                        &:active {
                            background: $color-lightBlue-active;
                        }
                    }
                }
            }

            .tabletNavigation__rightSide__wrapper {
                padding: 2rem 0;
                display: none;

                .tabletNavigation__buttonContainer {
                    display: flex;
                    flex-flow: column;
                    height: 18.6rem;
                    justify-content: space-between;
                    width: 10.6875rem;

                    .tabletNavigation__button__languageSelector {
                        margin-left: auto;
                    }
                }

                .tabletNavigation__buttonWrapper {
                    display: flex;
                    flex-direction: column;
                }

                .tabletNavigation__button__login {
                    background: $color-blue;
                    border-radius: 12px;
                    width: 100%;
                    height: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $color-white;
                    transition: background-color 0.3s ease;
                    font-size: 1rem;

                    &:hover {
                        background: $color-blue-hover;
                    }

                    &:active {
                        background: $color-blue-active;
                    }

                    .tabletNavigation__button__login__icon {
                        margin-right: 1rem;
                    }
                }

                .tabletNavigation__button__languageSelector {
                    font-size: 1rem;
                    font-family: "Roboto", serif;
                    font-weight: 500;
                    height: 3rem;
                    background: $color-lightBlue;
                    padding: 1rem;
                    border-radius: 12px;
                    width: 4rem;
                    color: $color-black;
                }

                .tabletNavigation__buttonWrapper {
                    display: flex;
                    justify-content: space-between;

                    .tabletNavigation__button__registrate {
                        color: $color-blue;
                        background: $color-lightBlue;
                        border-radius: 12px;
                        width: 100%;
                        height: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 1rem;
                        font-size: 1rem;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background: $color-lightBlue-hover;
                        }

                        &:active {
                            background: $color-lightBlue-active;
                        }
                    }
                }
            }
        }
    }
}
