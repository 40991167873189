@media screen and (max-width: 1295px) {
    .header {
        .header__grid {
            .logoContainer {
                margin-right: 0.6875rem;

                .logo {
                    width: 4.16rem;
                }
            }

            .desktopNavigation {
                .desktopNavigation__link {
                    padding: 0 0.5rem;
                    font-size: 0.875rem;
                }
            }

            .desktopNavigation__buttonContainer {
                .desktopNavigation__button__register {
                    width: 5.4375rem;
                    height: 1.875rem;
                    font-size: 0.875rem;
                    margin-right: 1rem;
                }

                .desktopNavigation__button__login {
                    width: 7.1875rem;
                    height: 1.875rem;
                    font-size: 0.875rem;
                    margin-right: 1rem;
                }

                .desktopNavigation__button__languageSelector {
                    .link {
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .main {
        .operationPrograms {
            .cardsContainer {
                .card {
                    .card__content {
                        display: block;

                        .card__graph {
                            width: 4.5rem;
                            float: left;

                            &::after {
                                content: "";
                                clear: both;
                                display: table;
                            }
                        }
                    }

                    .arrowContainer {
                        .arrow__wrapper {
                            width: 3.4375rem;
                            height: 3.4375rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1115px) {
    .itms-container {
        padding: 0 1.5rem !important;
    }

    .main {
        .main__hero__grid {
            .main__hero__grid__rightSide {
                .cardsContainer {
                    .card {
                        width: 13.75rem;

                        .card__graph {
                            width: 10.375rem;
                        }

                        .card__numberIconContainer {
                            .number {
                                font-size: 1.92rem;
                            }
                        }

                        &:last-of-type {
                            .card__graph {
                                width: 5rem;
                            }
                        }
                    }
                }
            }
        }

        .operationProgramData {
            .cardsContainer {
                .card {
                    .card__content {
                        .numberIconContainer {
                            .number {
                                font-size: 1.92rem;
                            }
                        }
                    }
                }
            }
        }

        .items {
            .item {
                width: 10.5625rem;

                .item__description {
                    font-size: 1.25rem;
                }
            }
        }

        .electronicTimesheet {
            .electronicTimesheet__grid {
                display: grid;
                grid-template-columns: repeat(2, auto);
                grid-template-rows: 1fr;
                grid-column-gap: 2rem;

                .imageContainer {
                    align-self: center;

                    .image {
                        width: 20rem;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1001px) {
    body {
        padding-top: 3rem !important;
    }

    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        border-bottom: 1px solid #e0e0e0;

        .header__grid {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 0;

            .desktopNavigation__buttonContainer,
            .desktopNavigation {
                display: none;
            }

            .mobileNavigation__iconContainer {
                display: block;
            }
        }
    }

    .main {
        .main__hero__grid {
            display: block;

            .main__hero__grid__leftSide {
                .map {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
            }

            .main__hero__grid__rightSide {
                .cardsContainer {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 1rem;
                    row-gap: 1rem;
                    align-items: flex-start;

                    .card {
                        width: 100%;
                        height: 100%;
                        margin: 0;

                        .number {
                            font-size: 2.5rem;
                        }

                        .card__graph {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }

        .grid__eurofondy {
            .grid__eurofondy__rightSide {
                .eurofondy__hero-image {
                    max-width: 30rem;
                    margin-top: 0;
                }
            }
        }

        .items {
            display: grid;
            grid-template-columns: repeat(auto-fill, 12rem);
            row-gap: 1rem;
            justify-items: center;
        }

        .operationPrograms {
            margin-top: 2rem;
        }

        .preFooter {
            .columnContainer {
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 2rem;
                grid-row-gap: 2rem;
            }
        }

        .operationProgramDetail,
        .openData,
        .mobileApplication,
        .elektronicky-pracovny-vykaz {
            .iconContainer {
                margin-top: 2rem;
            }
        }
    }

    .footer {
        .columnContainer {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 2rem;
            grid-row-gap: 2rem;
        }

        .copyrightContainer {
            display: block;

            .copyrightContainer__imageWrapper {
                justify-content: flex-start;
                margin-top: 1.3rem;

                .image {
                    margin-left: 0;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .main {
        .faqAndCalendar,
        .faqAndHowToStart {
            .faqAndCalendar__grid,
            .faqAndHowToStart__grid {
                grid-template-columns: 1fr;
                row-gap: 3rem;

                .howToStart {
                    .howToStart__column {
                        margin-bottom: 2rem;
                    }
                }
            }
        }

        .mobileApplication {
            .mobileApplication__grid {
                .mobileApplication__grid__rightSide {
                    .storesContainer {
                        .image {
                            max-height: 2.75rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .main {
        .grid__eurofondy {
            grid-template-columns: repeat(1, auto);

            .grid__eurofondy__rightSide {
                align-items: center;

                .eurofondy__hero-image {
                    width: 100%;
                    max-width: 40rem;
                    margin-top: 1.5rem;
                    height: auto;
                }
            }
        }

        .faqAndHowToStart {
            margin-top: 2.5rem;
        }

        .operationPrograms {
            .cardsContainer {
                .card {
                    .card__content {
                        .card__graph {
                            float: none;
                            margin-bottom: 0.5rem;
                        }

                        .text__content {
                            .card__heading {
                                font-size: 1.3rem;
                                margin-bottom: 1rem;
                            }

                            .columnContainer {
                                .column {
                                    display: block;

                                    .column__title {
                                        font-weight: 500;
                                        margin-bottom: 0.3rem;
                                    }

                                    .column__description:nth-of-type(1),
                                    :nth-of-type(2) {
                                        text-align: left;
                                        display: inline-block;
                                    }

                                    .column__description:nth-of-type(1) {
                                        margin-right: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .otherDataAboutOperationPrograms {
            .otherDataAboutOperationPrograms__accordion {
                .accordion__description-container {
                    .accordion__descriptionContainer {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .main {
        .operationProgramData {
            .cardsContainer {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .operationProgramDetail {
            .operationProgramDetail__grid {
                grid-template-columns: repeat(1, 1fr);

                .operationProgramDetail__grid__rightSide {
                    .graphContainer:first-of-type {
                        margin-bottom: 2rem;
                    }
                }
            }
        }

        .mobileApplication,
        .elektronicky-pracovny-vykaz {
            .heading {
                margin-bottom: 2.5rem;
            }

            .mobileApplication__grid,
            .elektronicky-pracovny-vykaz__grid {
                grid-template-columns: repeat(1, 1fr);

                .mobileApplication__grid__leftSide,
                .elektronicky-pracovny-vykaz__grid__leftSide {
                    .text:first-of-type {
                        margin-top: 0;
                    }
                }

                .mobileApplication__grid__rightSide,
                .elektronicky-pracovny-vykaz__grid__rightSide {
                    .imageContainer {
                        margin-bottom: 0;
                        .image {
                            max-width: 35rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .main {
        .main__hero__grid {
            .main__hero__grid__rightSide {
                .cardsContainer {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 1rem;
                    row-gap: 1rem;

                    .card {
                        width: 100%;
                        margin: 0;

                        & > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }

        .electronicTimesheet {
            margin-top: 1rem;

            .electronicTimesheet__grid {
                grid-template-columns: repeat(1, 1fr);
                row-gap: 2rem;

                .textContainer {
                    .buttonContainer {
                        display: block;

                        .moreInformationLinkContainer {
                            margin-top: 1.5rem;
                            justify-content: flex-start;
                        }
                    }
                }

                .imageContainer {
                    display: flex;
                    justify-content: center;
                    margin-top: 1rem;

                    .image {
                        max-width: 35rem;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .main {
        .operationProgramData {
            .cardsContainer {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 622px) {
    .main {
        .items {
            grid-template-columns: repeat(auto-fill, 13rem);
            justify-content: center;
        }
    }
}

@media screen and (max-width: 600px) {
    .main {
        .operationPrograms {
            .cardsContainer {
                grid-template-columns: 1fr;

                .card {
                    .card__content {
                        .card__graph {
                            width: 5rem;
                        }

                        .text__content {
                            .columnContainer {
                                .column {
                                    display: grid;
                                    grid-template-columns: 11.4rem 6.8125rem 3.4375rem;

                                    .column__title {
                                        font-weight: normal;
                                    }

                                    .column__description {
                                        &:nth-of-type(1) {
                                            margin: 0;
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .main {
        .main__hero__grid {
            display: block;

            .hero {
                .hero__heading {
                    font-size: 2.5rem;
                }

                .hero__button {
                    margin-bottom: 2rem;
                }
            }
        }

        .grid__eurofondy {
            .grid__eurofondy__leftSide {
                .hero {
                    .hero__heading {
                        font-size: 2.5rem;
                    }
                }
            }
        }

        .operationProgramDetail,
        .mobileApplication,
        .elektronicky-pracovny-vykaz .openData {
            .heading {
                font-size: 2.5rem;
            }
        }

        .preFooter {
            padding: 2rem 0;

            .columnContainer {
                grid-template-columns: repeat(1, 1fr);
                row-gap: 2rem;
            }
        }
    }

    .footer {
        padding: 2rem 0;

        .columnContainer {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 2rem;
        }

        .logoContainer {
            display: inline-block;
        }

        .copyrightContainer {
            display: block;

            .copyrightContainer__imageWrapper {
                justify-content: flex-start;
                margin-top: 1.3rem;

                .image {
                    margin-left: 0;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .main {
        .main__hero__grid {
            .main__hero__grid__leftSide {
                .map {
                    display: none;
                }
            }

            .main__hero__grid__rightSide {
                align-items: inherit;

                .cardsContainer {
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 2rem;
                    margin-top: 2rem;
                }
            }
        }

        .faqAndHowToStart {
            margin-top: 2rem;
        }
    }
}

@media screen and (min-width: 460px) {
    .header {
        .header__grid {
            .mobileNavigation {
                display: none;
                grid-template-columns: repeat(2, 1fr);

                .mobileNavigation__content {
                    display: flex;

                    .mobileNavigation__itemsContainer {
                        display: flex;
                        padding-bottom: 4rem;
                        text-align: left;
                    }
                }

                .mobileNavigation__buttonContainer {
                    display: none;
                }

                .tabletNavigation__rightSide__wrapper {
                    display: flex;
                }
            }
        }
    }
}

@media screen and (max-width: 459px) {
    .header {
        .header__grid {
            .mobileNavigation {
                .mobileNavigation__content {
                    .mobileNavigation__itemsContainer {
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .main {
        .operationPrograms {
            .cardsContainer {
                .card {
                    .card__content {
                        .card__graph {
                            float: none;
                        }

                        .text__content {
                            .card__heading {
                                font-size: 1.3rem;
                                margin-bottom: 1rem;
                            }

                            .columnContainer {
                                .column {
                                    display: block;

                                    .column__title {
                                        font-weight: 500;
                                        margin-bottom: 0.3rem;
                                    }

                                    .column__description:nth-of-type(1),
                                    :nth-of-type(2) {
                                        text-align: left;
                                        display: inline-block;
                                    }

                                    .column__description:nth-of-type(1) {
                                        margin-right: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .main {
        .operationProgramData {
            .cardsContainer {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .main {
        .operationProgramDetail {
            .operationProgramDetail__grid {
                .operationProgramDetail__grid__rightSide {
                    .graphContainer {
                        .card {
                            .card__content {
                                .card__graph {
                                    float: none;
                                }

                                .text__content {
                                    .card__heading {
                                        font-size: 1.3rem;
                                        margin-bottom: 1rem;
                                    }

                                    .columnContainer {
                                        .column {
                                            display: block;

                                            .column__title {
                                                font-weight: 500;
                                                margin-bottom: 0.3rem;
                                            }

                                            .column__description:nth-of-type(1),
                                            :nth-of-type(2) {
                                                text-align: left;
                                                display: inline-block;
                                            }

                                            .column__description:nth-of-type(1) {
                                                margin-right: 1rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobileApplication {
            .mobileApplication__grid {
                .mobileApplication__grid__rightSide {
                    .storesContainer {
                        .image {
                            max-height: 2rem;
                        }
                    }
                }
            }
        }
    }
}
