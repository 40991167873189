.footer {
    background: #f3f5f6;
    padding: 3.75rem 0;

    .columnContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 4.375rem;

        .column {
            .column__heading {
                margin-top: 0;
                margin-bottom: 1.25rem;
            }

            .column__text {
                margin-top: 0;
                font-weight: 500;
                color: $color-grey;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .column__link {
                color: $color-blue;
                margin-bottom: 1.25rem;
                display: block;
                font-weight: 500;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .logoContainer {
        display: flex;
        padding: 4.625rem 0 0 0;
        border-bottom: 2px solid $color-lightGrey;

        .logo {
            margin-right: 2rem;
            margin-bottom: 1.875rem;
        }
    }

    .copyrightContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 1.875rem;

        .copyrightContainer__textWrapper {
            .text {
                margin: 0 0 0.8125rem 0;
                font-weight: 500;
                color: $color-grey;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .copyrightContainer__imageWrapper {
            display: flex;
            justify-content: flex-end;

            .image {
                width: auto;
                height: 32px;
                margin-left: 0.5rem;
            }
        }
    }
}
